











import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import SharedPlanHeader from "@/components/Shared/Plan/Header.vue";
import SharedPlanList from "@/components/Shared/Plan/List.vue";
import { toDisplayName } from "@/helpers/Formatter";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
    components: {
        BaseWrapper,
        SharedPlanHeader,
        SharedPlanList,
    },
    metaInfo() {
        const me = this.$store.state.users.me;
        const page = "Rencana CSR - ";
        const title = page + toDisplayName(me);

        return {
            title: title,
        };
    },
})
export default class AppPlans extends Vue {
    public selectedYear: any = new Date().getFullYear();

    public plans: any[] = [];

    get me() {
        return this.$store.state.users.me;
    }

    get meRole() {
        return this.$store.getters["users/meRole"];
    }

    createPlan() {
        this.$router.push("/app/rencana/buat");
    }

    @Watch("me", { immediate: true })
    async getPlans(me: any) {
        if (!!me) {
            let plansQuery;
            const isCompany = this.meRole === "COMPANY";

            if (isCompany) {
                const userId = me.id;
                plansQuery = this.$store.dispatch("plans/getUserPlans", userId);
            } else {
                plansQuery = this.$store.dispatch(
                    "plans/getCurrentYearPlans",
                    this.selectedYear
                );
            }

            const response = await plansQuery;

            this.plans = response.plans;
        }
    }
}
